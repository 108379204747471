import { getUser } from './auth-client'

async function bootstrapAppData() {
  const data = await getUser()
  if (!data) {
    return { user: null }
  }

  const { data: user } = data

  return {
    user,
  }
}

// eslint-disable-next-line import/prefer-default-export
export { bootstrapAppData }
