import client from './api-client'

const localStorageKey = '__clinical_operator_token__'

function handleUserResponse(response) {
  const { data } = response
  window.localStorage.setItem(localStorageKey, data.token)
  return data
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return client('auth/operators/me').catch(error => {
    logout()
    return Promise.reject(error)
  })
}

function login({ email, password }) {
  return client('auth/operators/login', { body: { email, password } })
    .then(handleUserResponse)
    .catch(err => Promise.reject(err))
}

function register({ email, password }) {
  return client('register', { body: { email, password } }).then(
    handleUserResponse
  )
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
  return Promise.resolve()
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

export { login, register, logout, getToken, getUser }
