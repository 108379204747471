import React from 'react'
import './App.css'

import { FullPageSpinner } from '@covid/ui/core/loadings'
import { useUser } from './context/user'

const loadAuthenticatedApp = () => import('./AuthenticatedApp')
const NonAuthenticatedApp = React.lazy(() => import('./NonAuthenticatedApp'))
const AuthenticatedApp = React.lazy(() => loadAuthenticatedApp())

function App() {
  const user = useUser()
  // Pre-load the authenticated side in the background while the user's
  // is in the NonAuthenticatedApp part.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <NonAuthenticatedApp />}
    </React.Suspense>
  )
}

export default App
